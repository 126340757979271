<template>
  <div>
    <div class="grid crud-demo">
      <div class="col-12">
        <div class="card">
          <h6 class="">Administração de Clientes</h6>
          <Toast/>
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                <!--              <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedClientes || !selectedClientes.length" />-->
              </div>
            </template>

            <template v-slot:end>
              <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" />
              <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)"  />
            </template>
          </Toolbar>

          <DataTable ref="dt" :value="clientes" v-model:selection="selectedClientes" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clientes" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
              </div>
            </template>

            <!--          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
            <Column field="id" header="Código" :sortable="true" filterField="id" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Id</span>
                {{slotProps.data.id}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>
            <Column field="nome" header="Nome" :sortable="true" filterField="nome" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Nome</span>
                {{slotProps.data.nome}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo cliente"/>
              </template>
            </Column>
            <!--          <Column header="Image">-->
            <!--            <template #body="slotProps">-->
            <!--              <span class="p-column-title">Image</span>-->
            <!--              <img :src="'demo/images/cliente/' + slotProps.data.image" :alt="slotProps.data.image" class="shadow-2" width="100" />-->
            <!--            </template>-->
            <!--          </Column>-->
            <Column field="cidade" header="Cidade" :sortable="true" class="text-left" filterField="cidade" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Cidade</span>
                {{formatCurrency(slotProps.data.cidade)}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela cidade"/>
              </template>
            </Column>
            <Column field="estado" header="Estado" :sortable="true" class="text-right" filterField="estado" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Estado</span>
                {{slotProps.data.estado}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela estado"/>
              </template>
            </Column>
            <Column field="category" header="Categoria" :sortable="true" filterField="categoria" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Category</span>
                {{slotProps.data.category}}
              </template>

              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela categoria"/>
              </template>

            </Column>

            <Column field="status" header="Status" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'cliente-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{slotProps.data.status}}</span>
              </template>
            </Column>

            <Column header="Última Compra" dataType="date" style="min-width:10rem">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>

            </Column>

            <Column field="valor" header="Valor" class="text-right" :sortable="true" filterField="valor" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Valor</span>
                {{formatCurrency(slotProps.data.balance)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>


            <Column header="Ações" class="text-right">
              <template #body="slotProps">
<!--                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteCliente(slotProps.data)" />-->
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editCliente(slotProps.data)" />
<!--                <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2" @click="visibleTimeline = true" />-->
              </template>
            </Column>
          </DataTable>




          <Dialog v-model:visible="clienteDialog" :style="{width: '450px'}" header="Detalhes do Cliente" :modal="true" class="p-fluid">
            <!--          <img :src="'demo/images/cliente/' + cliente.image" :alt="cliente.image" v-if="cliente.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />-->
            <div class="field">
              <label for="name">Cliente</label>
              <InputText id="name" v-model.trim="cliente.name" required="true" autofocus :class="{'p-invalid': submitted && !cliente.name}" />
              <small class="p-invalid" v-if="submitted && !cliente.name">Cliente é obrigatório.</small>
            </div>
            <div class="field">
              <label for="description">Descrição</label>
              <Textarea id="description" v-model="cliente.description" required="true" rows="3" cols="20" />
            </div>

            <div class="field">
              <label for="inventoryStatus" class="mb-3">Status</label>
              <Dropdown id="inventoryStatus" v-model="cliente.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'cliente-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'cliente-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
            </div>

            <div class="field">
              <label class="mb-3">Categoria</label>
              <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                  <RadioButton id="category1" name="category" value="Accessories" v-model="cliente.category" />
                  <label for="category1">Grandes Negócios</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category2" name="category" value="Clothing" v-model="cliente.category" />
                  <label for="category2">Residencial</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category3" name="category" value="Electronics" v-model="cliente.category" />
                  <label for="category3">Rural</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category4" name="category" value="Fitness" v-model="cliente.category" />
                  <label for="category4">Comercial</label>
                </div>
              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col">
                <label for="price">Valor</label>
                <InputNumber id="price" v-model="cliente.price" mode="currency" currency="BRL" locale="pt-BR" />
              </div>
              <div class="field col">
                <label for="quantity">Potência</label>
                <InputNumber id="quantity" v-model="cliente.quantity" integeronly />
              </div>
            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveCliente" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteClienteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="cliente">Confirma a exclusão de <b>{{cliente.name}}</b>?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteClienteDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteCliente" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteClientesDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="cliente">Tem certeza que deseja deletar os itens selecionados?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteClientesDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedClientes" />
            </template>
          </Dialog>
        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>15 dias</h6>
          <Chart type="doughnut" :data="pieData" :options="pieOptions" style="position:relative; width: 50%"></Chart>
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Últimos Meses</h6>
          <Chart type="line" :data="lineData" :options="lineOptions"></Chart>
        </div>
      </div>
    </div>


    <div class="col-12 lg:col-12">
      <Sidebar v-model:visible="visibleTimeline" :baseZIndex="1000" position="right">
        <h3 style="font-weight: normal">Timeline</h3>
        <clientes-list-timeline></clientes-list-timeline>
      </Sidebar>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import ClienteService from '@/service/ClienteService';
import ClientesListTimeline from "@/components_esol/clientes/ClientesListTimeline";


export default {
  components: {
    ClientesListTimeline

  },
  data() {
    return {
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      clientes: null,
      clienteDialog: false,
      deleteClienteDialog: false,
      deleteClientesDialog: false,
      cliente: {},
      selectedClientes: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],

      pieData: [],
      pieOptions: [],

      lineData: [],
      lineOptions: [],

    }
  },
  clienteService: null,
  created() {
    this.clienteService = new ClienteService();
    this.initFilters();
  },
  mounted() {


    this.clienteService.getssclienteindex().then((data) => {
      console.log('clientesList mounted')
      this.loading1 = false
      this.clientes = data[0]
      let labelsPolarChart = []
      this.clientes.forEach((cliente) => {
        cliente.date = new Date(cliente.date)
        if (cliente.estado)
          labelsPolarChart.push(cliente.estado)
      });
      labelsPolarChart = [...new Set(labelsPolarChart)]

      let valuesPolarData = []
      labelsPolarChart.forEach((label) => {
        if (label != null && label != 'null'){
          console.log(label)
          let clientesFiltr =  this.clientes.filter(x => x.estado === label)
          //let valorTot = 0
          let contTot = 0
          clientesFiltr.forEach((cliente) => {
            if (cliente.estado)
              contTot += 1
            //console.log(orcto.valortotal)
          })
          valuesPolarData.push(contTot)
          // console.log('totalLabel')
          // console.log(totalLabel)
        }
      })

      let labelsLinesChart = data[1]
      let valuesLines1Chart = data[2]
      let valuesLines2Chart = data[3]


      this.pieData = {
        labels: labelsPolarChart,
            datasets: [
                {
                  data: valuesPolarData,
                  backgroundColor: [
                      'rgb(54, 162, 235)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 205, 86)',
                    'rgb(75, 192, 192)'
            ]
          }]
      }
      this.pieOptions =  {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        }
      }

      this.lineData = {
        labels: labelsLinesChart,
            datasets: [
          {
            label: 'Análise',
            data: valuesLines1Chart,
            fill: false,
            backgroundColor: 'rgb(255, 205, 86)',
            borderColor: 'rgb(255, 205, 86)',
            tension: .4
          },
          {
            label: 'Qualificado',
            data: valuesLines2Chart,
            fill: false,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            tension: .4
          }
        ]
      }

      this.lineOptions =  {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      }


    })


  },
  methods: {
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      this.$router.push({ path: '/clientedetalhe/0' });
    },
    hideDialog() {
      this.clienteDialog = false;
      this.submitted = false;
    },
    saveCliente() {
      this.submitted = true;
      if (this.cliente.name.trim()) {
        if (this.cliente.id) {
          this.cliente.inventoryStatus = this.cliente.inventoryStatus.value ? this.cliente.inventoryStatus.value: this.cliente.inventoryStatus;
          this.clientes[this.findIndexById(this.cliente.id)] = this.cliente;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Cliente Atualizado', life: 3000});
        }
        else {
          this.cliente.id = this.createId();
          this.cliente.code = this.createId();
          this.cliente.image = 'cliente-placeholder.svg';
          this.cliente.inventoryStatus = this.cliente.inventoryStatus ? this.cliente.inventoryStatus.value : 'ANÁLISE';
          this.clientes.push(this.cliente);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Cliente Criado', life: 3000});
        }
        this.clienteDialog = false;
        this.cliente = {};
      }
    },
    editCliente(cliente) {
      this.$router.push({ path: `/clientedetalhe/${cliente.id}` });
    },
    confirmDeleteCliente(cliente) {
      this.cliente = cliente;
      this.deleteClienteDialog = true;
    },
    deleteCliente() {
      this.clientes = this.clientes.filter(val => val.id !== this.cliente.id);
      this.deleteClienteDialog = false;
      this.cliente = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Cliente Deletado', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.clientes.length; i++) {
        if (this.clientes[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteClientesDialog = true;
    },
    deleteSelectedClientes() {
      this.clientes = this.clientes.filter(val => !this.selectedClientes.includes(val));
      this.deleteClientesDialog = false;
      this.selectedClientes = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Clientes Deletados', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},

        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'nome': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'estado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'categoria': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'valor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
